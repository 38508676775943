<template>
  <div>
    <div class="pa-3">
      <v-subheader>Plantilla</v-subheader>
      <v-divider></v-divider>
      <v-item-group class="d-flex align-center flex-wrap mt-2" mandatory style="gap: 20px"
        v-model="opcionesAutoestudio.plantilla">
        <v-item :value="val" v-for="[val, plantilla] in Object.entries(plantillas)" :key="val"
          v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="toggle">
            <h4>{{ plantilla.name }}</h4>
          </v-card>
        </v-item>
      </v-item-group>
      <v-subheader>Lecturas</v-subheader>
      <v-divider></v-divider>
      <v-item-group class="d-flex align-center flex-wrap mt-2" mandatory style="gap: 20px">
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="
            toggle();
          lecturas_manuales = null;
          ">
            <h4>Lecturas SIPS</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 rounded-md d-flex">
            <v-file-input dense hide-details filled @input="toggle" @change="toggle" v-model="lecturas_manuales"
              hint=".csv" accept=".csv" placeholder="Selecciona tu archivo con las lecturas"></v-file-input>
          </v-card>
        </v-item>
        <v-btn @click.stop="descargarPlantillaLecturas" small color="primary">
          <v-icon left>mdi-download</v-icon>
          Plantilla
        </v-btn>
      </v-item-group>
      <div class="my-2"></div>
      <v-subheader>Tipo de consumidor</v-subheader>
      <v-divider></v-divider>
      <v-item-group v-model="opcionesAutoestudio.curva" mandatory
        class="d-flex align-center justify-space-around flex-wrap mt-2" style="gap: 20px">
        <v-item v-for="[val, curva] in Object.entries(curvas)" :key="val" :value="val" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-4 ma-1"
            style="height: 150px; width: 150px; aspect-ratio: 1" @click.stop="toggle">
            <h4>{{ curva.name }}</h4>
            <v-icon large>{{ curva.icon }}</v-icon>
            <v-sparkline :value="curva.curva" color="secondary" line-width="6"></v-sparkline>
          </v-card>
        </v-item>
        <v-text-field style="max-width: 150px;" label="Excedentes" suffix="%"
          v-model="opcionesAutoestudio.excedentes_buscados" dense hide-details filled type="number"></v-text-field>
      </v-item-group>

      <div class="my-2"></div>
      <v-subheader>Tipo de estructura</v-subheader>
      <v-divider></v-divider>
      <v-item-group v-model="opcionesAutoestudio.estructura" mandatory class="d-flex align-center flex-wrap mt-2"
        style="gap: 20px">
        <v-item v-for="val in Object.keys(estructuras)" :key="val" :value="val" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>{{ val }}</h4>
          </v-card>
        </v-item>
      </v-item-group>

      <div class="my-2"></div>
      <v-subheader>Panel</v-subheader>
      <v-divider></v-divider>
      <v-item-group mandatory class="d-flex align-center flex-wrap mt-2" style="gap: 20px">
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="
            toggle();
          opcionesAutoestudio.idPanel = null;
          ">
            <h4>Según consumidor</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 rounded-md d-flex">
            <v-select :items="paneles" item-value="idPanel" dense hide-details filled @change="toggle"
              v-model="opcionesAutoestudio.idPanel">
              <template v-slot:selection="{ item }">
                <v-list-item-title>
                  {{ item.nombre }}
                  -
                  {{ item.potencia }} kWp
                </v-list-item-title>
              </template>
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.potencia }} kWp</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-card>
        </v-item>
      </v-item-group>

      <div class="my-2"></div>
      <!-- <v-text-field
                  filled
                  dense
                  hide-details="auto"
                  label="Potencia"
                  type="number"
                  v-model="opcionesAutoestudio.potencia_solicitada"
                ></v-text-field> -->
      <v-subheader>Potencia de la instalación</v-subheader>
      <v-divider></v-divider>
      <v-item-group class="d-flex align-center flex-wrap mt-2" mandatory style="gap: 20px">
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="
            toggle();
          opcionesAutoestudio.potencia_solicitada = null;
          ">
            <h4>Optimización automática</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" :disabled="!estudio.cubiertas" @click="
            toggle();
          opcionesAutoestudio.potencia_solicitada = 'Coste 0';
          ">
            <h4>Estudio coste 0</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 rounded-md d-flex">
            <v-text-field dense hide-details filled suffix="kWp" @input="toggle" type="number"
              v-model="opcionesAutoestudio.potencia_solicitada"></v-text-field>
          </v-card>
        </v-item>
      </v-item-group>

      <v-divider class="my-2"></v-divider>

      <template v-if="$root.acceso('EDITAR_ESTUDIOS')">
        <v-subheader>Precio de la instalación</v-subheader>
        <v-divider></v-divider>
        <v-item-group class="d-flex align-center flex-wrap mt-2" mandatory style="gap: 20px">
          <v-item v-slot="{ active, toggle }">
            <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="
              toggle();
            opcionesAutoestudio.precio_wp = null;
            ">
              <h4>Calcular según potencia</h4>
            </v-card>
          </v-item>
          <v-item v-slot="{ active, toggle }">
            <v-card outlined class="pa-2 rounded-md d-flex">
              <v-text-field dense hide-details filled suffix="€ Wp" @input="toggle" type="number"
                v-model="opcionesAutoestudio.precio_wp"></v-text-field>
            </v-card>
          </v-item>
        </v-item-group>
      </template>

      <v-subheader>Superficie disponible</v-subheader>
      <v-divider></v-divider>
      <v-item-group class="d-flex align-center flex-wrap mt-2" mandatory style="gap: 20px">
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" @click="
            toggle();
          opcionesAutoestudio.superficie_disponible = null;
          ">
            <h4>Indefinida</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 pa-2 px-4 rounded-md d-flex" :disabled="!estudio.cubiertas" @click="
            toggle();
          opcionesAutoestudio.superficie_disponible = 'cubiertas';
          ">
            <h4>Según cubiertas marcadas</h4>
          </v-card>
        </v-item>
        <v-item v-slot="{ active, toggle }">
          <v-card outlined class="pa-2 rounded-md d-flex">
            <v-text-field dense hide-details filled suffix="m²" @input="toggle" type="number"
              v-model="opcionesAutoestudio.superficie_disponible"></v-text-field>
          </v-card>
        </v-item>
      </v-item-group>

      <template v-if="$root.acceso('EDITAR_ESTUDIOS')">
        <v-divider></v-divider>
        <v-subheader>Término opcional *<small>Se aplicará al precio de instalación</small></v-subheader>
        <v-row class="align-center">
          <v-col>
            <v-text-field dense hide-details filled suffix="€" @input="toggle" type="number"
              v-model="opcionesAutoestudio.precio_extra"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense hide-details filled suffix="%" @input="toggle" type="number"
              v-model="opcionesAutoestudio.precio_extra_pct"></v-text-field>
          </v-col>
        </v-row>
      </template>

      <v-divider class="my-2"></v-divider>

      <v-subheader>Simulación comunidad</v-subheader>
      <v-text-field placeholder="Nº vecinos" filled dense hide-details v-model="opcionesAutoestudio.simulacion_vecinos"
        type="number"></v-text-field>

      <v-divider class="my-2"></v-divider>

      <v-row>
        <v-col cols="12" md="4">
          <v-text-field filled dense hide-details="auto" label="Precio medio kWh" type="number"
            v-model="opcionesAutoestudio.precio_medio"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field filled dense hide-details="auto" label="Inclinación" type="number"
            v-model="opcionesAutoestudio.inclinacion" suffix="º" :disabled="!!estudio.inclinacion"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field filled dense hide-details="auto" label="Azimut" type="number"
            v-model="opcionesAutoestudio.azimut" suffix="º" :disabled="!!estudio.azimut"></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="my-2"></v-divider>

      <v-row class="align-center">
        <v-col cols="12" md="3">
          <v-checkbox filled dense hide-details="auto" v-model="opcionesAutoestudio.calcular_financiacion"
            label="Incluir financiacion"></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" v-if="$root.acceso('EDITAR_ESTUDIOS')">
          <v-text-field filled dense hide-details="auto" type="number" v-model="opcionesAutoestudio.interes"
            :disabled="!opcionesAutoestudio.calcular_financiacion" label="Interes anual"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field filled dense hide-details="auto" type="number" label="Años financiacion"
            v-model="opcionesAutoestudio.años_financiacion"
            :disabled="!opcionesAutoestudio.calcular_financiacion"></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="my-2"></v-divider>

      <v-row>
        <v-col cols="12">
          <v-checkbox filled dense hide-details="auto" v-model="opcionesAutoestudio.aplicar_iva"
            label="Aplicar IVA"></v-checkbox>
        </v-col>
      </v-row>
      <div class="my-2"></div>
      <v-subheader>Promoción</v-subheader>
      <v-divider></v-divider>
      <v-item-group v-model="opcionesAutoestudio.promocion" mandatory class="d-flex align-center flex-wrap mt-2"
        style="gap: 20px">
        <v-item :value="null" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>Automático</h4>
          </v-card>
        </v-item>
        <v-item value="NINGUNA" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>Ninguna</h4>
          </v-card>
        </v-item>
        <v-item v-for="val in promociones" :key="val.idPromocion" :value="val.idPromocion" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>{{ val.nombre }}</h4>
          </v-card>
        </v-item>
      </v-item-group>

      <div class="my-2"></div>
      <v-subheader>Subvencion</v-subheader>
      <v-divider></v-divider>
      <v-item-group v-model="opcionesAutoestudio.subvencion" mandatory class="d-flex align-center flex-wrap mt-2"
        style="gap: 20px">
        <v-item :value="null" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>No</h4>
          </v-card>
        </v-item>
        <v-item v-for="[val, name] in Object.entries(subvenciones)" :key="val" :value="val" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>{{ name }}</h4>
          </v-card>
        </v-item>
      </v-item-group>

      <div class="my-2"></div>
      <v-subheader>PPA</v-subheader>
      <v-divider></v-divider>
      <v-item-group v-model="opcionesAutoestudio.ppa" mandatory class="d-flex align-center flex-wrap mt-2"
        style="gap: 20px">
        <v-item :value="null" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>No</h4>
          </v-card>
        </v-item>
        <v-item v-for="[val, ppa] in Object.entries(ppas)" :key="val" :value="val" v-slot="{ toggle }">
          <v-card outlined class="item-selection align-center pa-2 px-4 ma-1" @click.stop="toggle">
            <h4>{{ ppa.name }}</h4>
          </v-card>
        </v-item>
      </v-item-group>

      <v-divider class="my-2"></v-divider>

      <v-row class="align-center">
        <v-col cols="12" md="3">
          <v-checkbox filled dense hide-details="auto" v-model="opcionesAutoestudio.precio_oferta_alumbra"
            label="Oferta Alumbra"></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" v-if="$root.acceso('EDITAR_ESTUDIOS')">
          <v-text-field filled dense hide-details="auto" type="number"
            v-model="opcionesAutoestudio.precio_oferta_compra_alumbra"
            :disabled="!opcionesAutoestudio.precio_oferta_alumbra" required label="Precio compra"
            placeholder="0.08"></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field filled dense hide-details="auto" type="number" label="Precio venta"
            v-model="opcionesAutoestudio.precio_oferta_venta_alumbra" required v-bind:value="{ number: 0.03 }"
            :disabled="!opcionesAutoestudio.precio_oferta_alumbra"></v-text-field>
        </v-col>
      </v-row>

    </div>

    <v-dialog :value="error" max-width="500">
      <v-card>
        <v-alert class="ma-0" type="error" outlined text>
          {{ error }}
        </v-alert>
      </v-card>

    </v-dialog>
  </div>
</template>

<script setup>
import { downloadFile } from "@/utils/index.js";
import { useVModel } from "@vueuse/core";
import { ref, watch, computed } from "vue";
import axios from "@/utils/axiosWrapper";

const props = defineProps({
  idEstudio: Number,
  estudio: Object,
  value: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits([
  "update:creatingAutoestudio",
  "update:value",
  "reload",
]);

const opcionesAutoestudio = useVModel(props, "value", emit);

opcionesAutoestudio.value = {
  curva: "SERVICIO_GENERAL",
  precio_medio_kWh: null,
  show: false,
  potencia_solicitada: null,
  calcular_financiacion: true,
  precio_wp: null,
  precio_extra: 0,
  precio_extra_pct: 1,
  inclinacion: props.estudio.inclinacion,
  azimut: props.estudio.azimut,
  superficie_disponible: null,
  simulacion_vecinos: null,
  aplicar_iva: false,
  promocion: null,
  precio_oferta_alumbra: true,
  precio_oferta_venta_alumbra: 0,
  precio_oferta_compra_alumbra: 0,
  ...opcionesAutoestudio.value,
};

const lecturas_manuales = ref(null);

const {
  data,
  error,
  pending: creatingAutoestudio,
  refresh: crearEstudio,
} = axios({
  method: "POST",
  url: `${process.env.VUE_APP_API_URL}/autoestudios/${props.idEstudio}`,
  responseType: "blob",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  lazy: true,
  block: true,
  transformError: async (err) => {
    return JSON.parse(await err.response.data.text()).detail;
  },
});

watch(creatingAutoestudio, (v) => emit("update:creatingAutoestudio", v));

const { data: plantillas } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/plantillas`,
  { default: () => [] }
);
const { data: estructuras } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/estructuras`,
  { default: () => [] }
);
const { data: curvas } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/curvas`,
  { default: () => ({}) }
);
const { data: paneles } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/paneles`,
  { default: () => [] }
);
const { data: promociones } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/promociones`,
  { default: () => [] }
);
const { data: subvenciones } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/subvenciones`,
  { default: () => [] }
);
const { data: ppas } = axios(
  `${process.env.VUE_APP_API_URL}/autoestudios/ppas`,
  { default: () => [] }
);

function descargarPlantillaLecturas() {
  const plantilla = `Fecha Lectura Anterior;Fecha Lectura;kWh1\n01/01/2022;01/02/2022;0\n01/02/2022;01/03/2022;0\n01/03/2022;01/04/2022;0\n01/04/2022;01/05/2022;0\n01/05/2022;01/06/2022;0\n01/06/2022;01/07/2022;0\n01/07/2022;01/08/2022;0\n01/08/2022;01/09/2022;0\n01/09/2022;01/10/2022;0\n01/10/2022;01/11/2022;0\n01/11/2022;01/12/2022;0\n01/12/2022;31/12/2022;0\n`;
  downloadFile(
    new File([plantilla], "plantilla_lecturas_estudio_express.csv", {}),
    false
  );
}

async function estudioExpress() {
  emit("update:creatingAutoestudio", true);
  error.value = null;

  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      tipo: "estudio_express",
      ...opcionesAutoestudio.value,
    })
  );
  if (lecturas_manuales.value)
    formData.append("lecturas_manuales", lecturas_manuales.value);

  await crearEstudio({ data: formData, block: true });

  if (error.value) {
  } else {
    downloadFile(
      new File([data.value], `estudio_express_${props.idEstudio}.pdf`, {
        type: "application/pdf",
      }),
      true
    );
  }

  emit("reload");
}

const superficieDisponible = computed(() => {
  return props.estudio?.cubiertas.reduce((p, a) => p + a.area, 0);
});

defineExpose({ estudioExpress })

</script>

<style></style>
